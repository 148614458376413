<template>
  <div class="container admin-page">
    <nav>
      <router-link tag="button" to="/">
        <button @click="denied">Back to feed</button>
      </router-link>
    </nav>

    <div class="controls">
      <div class="control">
        <h1>Number of users using eFan.gg</h1>
        <h1>{{ userCount }}</h1>
      </div>
      <div class="control">
        <h1>User Information</h1>
        <a :href="endpoint"
          >Click on me to export :)</a
        >
      </div>
      <div class="control">
        <h1>Refer link visitors</h1>
        <input type="text" ref="input" v-model="filterValue" placeholder="Search by timestamp or label" required />
        <div class="tbl-header">
          <table cellpadding="0" cellspacing="0" border="0">
            <thead>
              <tr>
                <th class="tbl-header--label">Label</th>
                <th class="tbl-header--timestamp">Timestamp</th>
              </tr>
            </thead>
          </table>
        </div>
        <div class="tbl-content">
          <!-- TODO: Add a input for filtering the table -->
          <table cellpadding="0" cellspacing="0" border="0">
            <tbody>
              <tr v-for="log in referLinkLogs">
                <td class="tbl-content--label" v-if="showLog(log)">{{ log.label }}</td>
                <td class="tbl-content--timestamp" v-if="showLog(log)">{{ log.created_at }}</td>
              </tr>
              <tr v-if="showNoLogsFoundForFilter()">
                <td class="tbl-content--label">
                  No logs found for filter {{ filterValue }}.
                </td>
                <td class="tbl-content--timestamp"></td>
              </tr>
              <tr v-if="referLinkLogs.length <= 0">
                <td class="tbl-content--label">No refer link clicks yet.</td>
                <td class="tbl-content--timestamp"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Helpers
import helper from "@/services/helper";

export default {
  data() {
    return {
      userCount: 0,
      referLinkLogs: [],
      filterValue: "",
      endpoint: helper.endpoint + "user/download/csv"
    }
  },
  methods: {
    denied() {
      this.$store.setAllowStats(false)
    },
    getFile() {
      // link mora biti dinamičen
      this.$router.push({ path: this.endpoint })
    },
    showNoLogsFoundForFilter() {
      if (this.filterValue.length <= 0) return false;
      const filteredLogs = this.referLinkLogs.filter(log => {
        return log.label.toLowerCase().includes(this.filterValue.toLowerCase()) ||
          log.created_at.toLowerCase().includes(this.filterValue.toLowerCase());
      });

      if (filteredLogs.length <= 0) {
        return true;
      } else {
        return false;
      }
    },
    showLog(log) {
      if (this.filterValue.length <= 0) return true;

      return log.label.toLowerCase().includes(this.filterValue.toLowerCase()) ||
        log.created_at.toLowerCase().includes(this.filterValue.toLowerCase());
    },
  },
  async mounted() {
    const userCount = await this.$api.getUserCount(this.userStore.accessToken);
    await this.$api.getReferLinkVisitLogs()
      .then(response => {
        this.referLinkLogs = response.logs;
      })
    this.userCount = userCount.userCount;
  },
  computed: {
    ...mapStores(useUserStore),
  }

}
</script>

<style lang="scss" scoped>
.admin-page {
  width: 60%;
  margin: 0 auto;

  @include respond-to("medium") {
    width: 90%;
  }

  button {
    @include button($gradient: true);
  }
  .controls {
    margin-bottom: 20px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    .control {
      margin-bottom: 30px;
      border-top: 2px solid var(--orange);
      display: flex;
      flex-direction: column;

      h1 {
        color: var(--text);
        text-align: center;
      }
      h2 {
        margin: 0;
        color: var(--text);
        font-size: 1em;
        text-align: center;
      }
      a {
        color: white;
        text-decoration: none;
        width: 80%;
        margin: 0 auto;
        background: var(--orange);
        text-align: center;
        border-radius: 10px;
        padding: 20px;
      }

      input {
        max-width: 40%;
        margin-bottom: 15px;

        @include respond-to("small") {
          max-width: 100%;
        }

        flex-grow: 1;
        background: #fff;
        border: none;
        padding: 17px;
        border-radius: 15px;

        @include placeholder {
            color: #8b8a92;
            font-size: 1.1em;
        }

        &:focus-within {
          outline: solid #fff 1px;
          outline-offset: 1px;
          border: 1px solid black;
        }
      }
      
      .tbl-header {
        @include gradient;
        border-radius: 15px 15px 0 0;

        &--label {
          min-width: 150px;
          width: 100%;
        }

        &--timestamp {
          min-width: 165px;
          text-align: left;
        }
      }

      .tbl-content{
        @include scrollbars;
        max-height: 300px;
        overflow-x:auto;
        margin-top: 0px;
        border: 1px solid rgba(255,255,255,0.3);

        &--label {
          min-width: 150px;
          width: 100%;
        }

        &--timestamp {
          min-width: 150px;
          text-align: left;
        }

        table {
          overflow: hidden;
        }

      }

      th {
        padding: 20px 15px;
        // min-width: 150px;
        text-align: left;
        font-weight: 500;
        color: #fff;
        text-transform: uppercase;
      }

      td {
        padding: 15px;
        // min-width: 150px;
        text-align: left;
        vertical-align:middle;
        color: #fff;
        border-bottom: solid 1px rgba(255,255,255,0.1);
      }
    }
  }
  .exportBtn {
    @extend .pointer;
    @extend .nodrag;
    padding: 1em;
    font-size: 1em;
    font-weight: bold;
  }
}
</style>
